
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    description: VueTypes.string,
    title: VueTypes.string,
    uri: VueTypes.string,
    visual: VueTypes.objectOf({
      kind: VueTypes.string,
    }),
    hideImageOnMobile: VueTypes.bool.def(false),
  },
};
