
import VueTypes from 'vue-types';

export default {
  inheritAttrs: false,
  props: {
    showAll: VueTypes.bool.def(false),
    heading: VueTypes.string,
    showAllLabel: VueTypes.string,
    entries: VueTypes.array,
    locationEntry: VueTypes.array,
    category: VueTypes.arrayOf({
      id: VueTypes.string,
    }),
    newsType: VueTypes.arrayOf({
      id: VueTypes.string,
    }),
  },
  computed: {
    showAllLink() {
      let link =
        this.locationEntry && this.locationEntry.length
          ? this.$first(this.locationEntry).uri
          : '/actueel';

      const params = [];
      if (this.newsType && this.newsType.length) {
        params.push(`type=${this.newsType[0].id}`);
      }
      if (this.category && this.category.length) {
        this.category.forEach((category) => {
          params.push(`category[]=${category.id}`);
        });
      }

      if (params.length) {
        link = `${link}?${params.join('&')}`;
      }

      return link;
    },
  },
};
