import { render, staticRenderFns } from "./NewsGrid.vue?vue&type=template&id=0c5d5536"
import script from "./NewsGrid.vue?vue&type=script&lang=js"
export * from "./NewsGrid.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconButton: require('/workspace/app/components/buttons/IconButton.vue').default,DefaultCard: require('/workspace/app/components/cards/DefaultCard.vue').default,Container: require('/workspace/app/components/layout/Container.vue').default})
